<template>
  <div>
    <div v-if="show" id="invoice" style="background-color: #FFFFFF;text-align: initial;padding: 30px 15px;">
      <div class="export-container">
        <div class="section-for-export" id="sectionForExport">
          <table class="table">
            <thead>
            <tr>
              <th v-for="(col, index) in header" :key="index">
                {{$t('bank_checking_history.'+col)}}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(row, index) in data" :key="index">
              <td v-for="(col, index2) in columns" :key="index2">
                {{row[col]}}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import i18nService from "@/core/services/i18n.service";
import {jsPDF} from "jspdf";
import jsPdfExport from "@/core/config/jsPdfExport";

export default {
  name: "Print",
  data() {
    return {
      mainRoute: 'finances/bank-check/history',
      data: {},
      show: false,
      type: this.$route.params.type ? this.$route.params.type : null,
      filters: {
        treasury_id: this.$route.query.treasury_id ? this.$route.query.treasury_id : null,
        from_date: this.$route.query.from_date ? this.$route.query.from_date : null,
        to_date: this.$route.query.to_date ? this.$route.query.to_date : null,
        user_id: this.$route.query.user_id ? this.$route.query.user_id : null,
      },
      columns: ['bank_name', 'current_amount', 'actual_amount', 'currency_name', 'last_validated_date'],
      header: ['cash', 'current_amount', 'currency_name', 'last_validate_date', 'actual_amount'],

    }
  },
  methods: {
    async getData() {
      await ApiService.get(`${this.mainRoute}`,{params: {...this.filters, export: 1}}).then((response) => {
        this.data = response.data.data;
        this.show = true;
      });
    },
    printInvoice() {
      let _id = 'invoice';
      this.exportPDF(_id);
    },
    exportPDF(_id) {
      let that = this;
      // let pdf = jsPDF("p", "px", 'a4');
      //
      // const AmiriRegular = pdf.loadFile("https://rawcdn.githack.com/MrRio/jsPDF/cbc85b4bb66d5c1a2d50d199e8bf33836f2f12d7/test/reference/Amiri-Regular.ttf", true);
      // pdf.addFileToVFS("Amiri-Regular.ttf", AmiriRegular);
      //
      // pdf.addFont("Amiri-Regular.ttf", "Amiri", "normal");
      let pdf = jsPdfExport("p", "px", 'a4');
      pdf.html(document.getElementById(_id), {
        html2canvas: {
          scale: 0.5,
        },
        callback: function (pdf) {
          if (that.type == 'pdf') {
            pdf.save('invoice');
          } else {
            pdf.autoPrint();
            pdf.output('dataurlnewwindow');
          }

          // var iframe = document.createElement('iframe');
          // iframe.setAttribute('style', 'position:absolute;top:0;right:0;height:100%; width:600px');
          // document.body.appendChild(iframe);
          // iframe.src = pdf.output('datauristring');
        }
      });
    },
  },
  mounted() {

    let promise = this.getData();
    Promise.all([promise]).then(() => {
      this.printInvoice();
    });
  }
}
</script>

<style >
#invoice {
  font-family: "Amiri";
  width: 235mm;
}
</style>